<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Driving Licence</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label for="formdrivinginput" class="form-label">Driving Licence No
                                <span class="text-danger">*</span></label>
                            <input type="text" v-model="drivingnumber" class="form-control text-uppercase"
                                id="formdrivinginput" :disabled="inputdisabled" placeholder="Enter Driving Licence No"
                                autocomplete="off" maxlength="15" />
                            <span class="custom-error" v-if="errdriving">
                                {{ errdriving }}
                            </span>
                            <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label for="formBirthDateInput" class="form-label">Birth Date<span
                                    class="text-danger">*</span></label>
                            <Calendar v-model="licencebirthdate" :disabled="inputdisabled" :minDate="minDateBirth"
                                :maxDate="maxDateBirth" :showIcon="true" class="custom-ultima-calendar"
                                placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true"
                                :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body" :yearNavigator="true">
                            </Calendar>
                        </div>
                    </div>
                    <div class="
                          col-lg-4 col-md-4 col-12
                        ">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="header-btn-outer btn btn-primary" @click="getdrivingdetails()"
                                :disabled="errStatus || drivingnumber.length == 0 || this.licencebirthdate == null || inputdisabled"
                                :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                spinner-border
                                text-light
                                custom-spinner-loader-btn
                              " role="status" v-if="showloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12 text-end" v-if="clearbtnflag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                                All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" :style="{ height: '378px' }" v-if="mobiledetailshowflag">
                    <div class="voter-exit-box-outer">
                        <div class="row">
                            <!-- <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                                <div class="customer-label-group">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <img v-if="LicenseTabDetails.ac17 == null ||
                                                LicenseTabDetails.ac17 == ''
                                                " src="/assets/images/avatar.png"
                                                class="rounded bg-light me-auto d-block" alt="" width="90" />
                                            <img :src="this.LicenseTabDetails.ac17" class="rounded bg-light me-auto d-block"
                                                alt="" width="90" v-if="LicenseTabDetails.ac17 != null ||
                                                    LicenseTabDetails.ac17 != ''
                                                    " />
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">License Number</label>
                                            <div class="from-label-value">
                                                {{
                                                    LicenseTabDetails.ac3
                                                    ? LicenseTabDetails.ac3
                                                    : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Name</label>
                                            <div class="from-label-value">
                                                {{
                                                    LicenseTabDetails.ac4
                                                    ? LicenseTabDetails.ac4
                                                    : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Date of Issue</label>
                                            <div class="from-label-value">
                                                {{ format_date(LicenseTabDetails.ac14) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Date of Expiry</label>
                                            <div class="from-label-value">
                                                {{ format_date(LicenseTabDetails.ac13) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider type="dashed" class="mt-0 mb-2" />
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Date of Birth</label>
                                    <div class="from-label-value">
                                        {{ format_date(LicenseTabDetails.ac12) }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Gender</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac11
                                            ? LicenseTabDetails.ac11 == 1
                                                ? "Male"
                                                : LicenseTabDetails.ac11 == 2
                                                    ? "Female"
                                                    : "Others"
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Father / Husband Name</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac15
                                            ? LicenseTabDetails.ac15
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <Divider type="dashed" class="mt-0 mb-2" />
                            <div class="col-lg-8 col-md-8 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Permanent Address</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac5 ? LicenseTabDetails.ac5 : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Permanent Pincode</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac6 ? LicenseTabDetails.ac6 : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <Divider type="dashed" class="mt-0 mb-2" />
                            <div class="col-lg-8 col-md-8 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Temporary Address</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac7 ? LicenseTabDetails.ac7 : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Temporary Pincode</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac8 ? LicenseTabDetails.ac8 : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <Divider type="dashed" class="mt-0 mb-2" />
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Ola Name</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac9 ? LicenseTabDetails.ac9 : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Ola Code</label>
                                    <div class="from-label-value">
                                        {{
                                            LicenseTabDetails.ac10
                                            ? LicenseTabDetails.ac10
                                            : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            drivingnumber: "",
            licencebirthdate: null,
            LicenseTabDetails: "",
            errmsg: '',
            maxDateBirth: moment().subtract(1, "years")._d,
            minDateBirth: moment().subtract(120, "years")._d,
            showloader: false,
            mobiledetailshowflag: false,
            errdriving: "",
            errStatus: false,
            inputdisabled: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    watch: {
        drivingnumber(drivingnumber) {
            this.errmsg = "";
            this.validateDrivingNumber(drivingnumber);
            if (drivingnumber.length == 0) {
                this.errdriving = "";
                this.errStatus = false;
            }
        },
    },
    mounted() {
    },
    methods: {
        getdrivingdetails() {
            this.showloader = true;
            let fields = {};
            fields["dl_number"] = this.drivingnumber;
            fields["dob"] = moment(this.licencebirthdate).format('YYYY-MM-DD');
            this.ApiService.getDLDetails(fields).then((data) => {
                if (data.status == true) {
                    this.LicenseTabDetails = data.data;
                    this.mobiledetailshowflag = true;
                    this.showloader = false;
                    this.inputdisabled = true;
                    this.clearbtnflag = true;
                } else {
                    this.mobiledetailshowflag = false;
                    this.showloader = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = true;
                }
            });
        },
        validateDrivingNumber(e) {
            this.errStatus = false;
            if (!this.validDrivingNo(e)) {
                this.errdriving = "Please enter valid driving licence no";
                this.errStatus = true;
            }
            if (this.errStatus == false) {
                this.errdriving = "";
                return true;
            }
        },

        validDrivingNo: function (e) {
            var re = /([A-Za-z]){2}([0-9]){13}$/;
            return re.test(e);
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        searchvoterclearbtn() {
            this.drivingnumber = '';
            this.licencebirthdate = null;
            this.LicenseTabDetails = '';
            this.errmsg = '';
            this.showloader = false;
            this.mobiledetailshowflag = false;
            this.errdriving = '';
            this.errStatus = false;
            this.inputdisabled = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>